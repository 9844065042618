import React, { Component } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { SceneProps, LayoutMachine, MMSData } from 'types';
import GLTFLoader from 'three-gltf-loader';
import { Scene, Vector3 } from 'three';
import TWEEN from '@tweenjs/tween.js';
import screenfull from 'screenfull';
import { getDataSourceSrv } from '@grafana/runtime';
import axios from 'axios';

class ThreeScene extends Component<SceneProps> {
  //THREE core objects
  scene!: THREE.Scene;
  mount: any;
  camera!: THREE.PerspectiveCamera;
  renderer!: THREE.WebGLRenderer;
  cube = new THREE.Mesh();
  frameId: any;
  controls!: OrbitControls;
  clock!: THREE.Clock;
  state: any = {
    showPanelLeft: false,
    showPanelBottom: false,
  };

  //machine objects
  machineMesh: any = null; //Same as MAchine boxes, added for easy removal on GLB loaded
  machineImage: any = null; //picture of the machine, shown on one side of the machine box
  machineBase: any = null; //Machine base which shows the status below the machine box
  glbMachineStatusBase: any = null; //GLB machine base which shows the status below the machine model
  machineLamp: any = null; //Lamp showing the status on top of the machine box
  machineLampBase: any = null; //Lamp base
  machineNumberSprite: any = null;
  machineAnimation: any = null;
  isGlb: boolean = false;
  rotate: number = 0;

  //default machine image
  defaultMachineImage: THREE.Texture | any;

  //Alarm icon, rotating on top of the machine
  alarmObject: any = null;
  alarmsDic: any = {};

  //Wrench icon, rotating on top of the machine
  wrenchObject: any = null;
  wrenchDic: any = {};

  //selecting machine
  mouse = new THREE.Vector2();
  mouseHasMoved = false;
  INTERSECTED: any;
  selectionMaterial = new THREE.MeshBasicMaterial({
    color: '#007ED6',
    transparent: true,
    opacity: 0.4,
    side: THREE.DoubleSide,
    depthWrite: false,
  });
  selectionMesh: any;
  selectionStatusText: any;

  proxyUrl: string | undefined;

  //touch
  touchTimer: any;
  touchDuration = 1000;

  constructor(props: Readonly<SceneProps>) {
    super(props);
  }

  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <div
          style={{ width: '100%', height: '100%' }}
          ref={mount => {
            this.mount = mount;
          }}
        />
      </div>
    );
  }

  rotateObjects() {
    if (this.alarmsDic && this.alarmsDic.rotation) {
      this.alarmsDic.rotation.y -= 0.05;
    }
    if (this.wrenchDic && this.wrenchDic.rotation) {
      this.wrenchDic.rotation.y -= 0.05;
    }
  }

  loadAlarmObject() {
    const loader = new GLTFLoader();
    loader.load(
      '/public/img/factory-layout/obj/att.gltf',
      gltf => {
        gltf.scene.scale.set(this.props.options.alarmIconSize, this.props.options.alarmIconSize, this.props.options.alarmIconSize);
        gltf.scene.children.forEach(element => {
          const material = (element as THREE.Mesh).material as THREE.MeshBasicMaterial;
          material.color.setHex(0xff002a);
        });
        this.alarmObject = gltf.scene;
      },
      undefined,
      error => console.log(error)
    );
  }

  loadWrenchObject() {
    const loader = new GLTFLoader();
    loader.load(
      '/public/img/factory-layout/obj/wrench.gltf',
      gltf => {
        gltf.scene.scale.set(this.props.options.wrenchIconSize, this.props.options.wrenchIconSize, this.props.options.wrenchIconSize);
        gltf.scene.children.forEach(element => {
          const material = (element as THREE.Mesh).material as THREE.MeshBasicMaterial;
          material.color.setHex(0xff7f00);
        });
        this.wrenchObject = gltf.scene;
      },
      undefined,
      error => console.log(error)
    );
  }

  loadMachineImage(machineMesh: THREE.Mesh) {
    const loader = new THREE.TextureLoader();
    loader.load(
      '/public/img/factory-layout/machines_transparent/' + machineMesh.userData.sensorId + '.png',
      texture => {
        this.applyMachineImage(machineMesh, texture);
      },
      undefined,
      err => {
        console.log(err);
        //apply default texture
        if (this.defaultMachineImage) {
          this.applyMachineImage(machineMesh, this.defaultMachineImage);
        } else {
          this.loadDefaultMachineImage(machineMesh);
        }
      }
    );
  }

  loadDefaultMachineImage(machineMesh: THREE.Mesh) {
    const loader = new THREE.TextureLoader();
    loader.load(
      '/public/img/factory-layout/machines_transparent/machine_default.png',
      texture => {
        this.defaultMachineImage = texture;
        this.applyMachineImage(machineMesh, texture);
      },
      undefined,
      err => {
        console.log(err);
      }
    );
  }

  applyMachineImage(machineMesh: THREE.Mesh, texture: THREE.Texture) {
    texture.needsUpdate = true;
    texture.wrapS = THREE.ClampToEdgeWrapping;
    texture.wrapT = THREE.ClampToEdgeWrapping;
    let boxWidth = machineMesh.userData.width;
    if (machineMesh.userData.face === 'east' || machineMesh.userData.face === 'west') {
      boxWidth = machineMesh.userData.depth;
    }
    const boxHeight = machineMesh.userData.height > 1 ? machineMesh.userData.height : machineMesh.userData.depth;
    const repeatX = (boxWidth * texture.image.height) / (boxHeight * texture.image.width);
    if (repeatX < 1) {
      const repeatY = (boxHeight * texture.image.width) / (boxWidth * texture.image.height);
      texture.repeat.set(1, repeatY);
      texture.offset.y = ((repeatY - 1) / 2) * -1;
    } else {
      texture.repeat.set(repeatX, 1);
      texture.offset.x = ((repeatX - 1) / 2) * -1;
    }
    texture.anisotropy = this.renderer.getMaxAnisotropy();
    this.machineImage.material = new THREE.MeshBasicMaterial({
      map: texture,
      color: 0xffffff,
      transparent: true,
    });
    this.updateMachineStatus(machineMesh);
  }

  onMouseDown(e: any) {
    if (this.INTERSECTED) {
      this.INTERSECTED.clicked = true;
    }
  }

  updateOnMouseMove() {
    if (!this.mouseHasMoved) {
      return;
    }
    const vector = new THREE.Vector3(this.mouse.x, this.mouse.y, 1);
    vector.unproject(this.camera);
    const ray = new THREE.Raycaster(this.camera.position, vector.sub(this.camera.position).normalize());
    const intersects = ray.intersectObjects([this.machineMesh]);
    if (intersects.length > 0) {
      this.mount.style.cursor = 'pointer';
      if (intersects[0].object !== this.INTERSECTED) {
        this.INTERSECTED = intersects[0].object;
        this.INTERSECTED.clicked = false;
        this.selectMachine();
      }
    } else {
      this.mount.style.cursor = 'default';
      if (this.INTERSECTED) {
        this.unselectMachine();
      }
      this.INTERSECTED = null;
    }
    this.controls.update();
  }

  selectMachine() {
    if (this.selectionMesh) {
      this.scene.remove(this.selectionMesh);
    }
    if (this.selectionStatusText) {
      this.scene.remove(this.selectionStatusText);
    }
    const dimentions = this.getMeshDimentions(this.INTERSECTED);
    const geometry = new THREE.BoxGeometry(dimentions.width * 1.1, dimentions.height * 1.1, dimentions.depth * 1.1);
    let yOffset = dimentions.height / 2;
    if(this.isGlb){
      yOffset = 0;
    }
    this.selectionMesh = new THREE.Mesh(geometry, this.selectionMaterial);
    this.selectionMesh.position.set(this.INTERSECTED.position.x, this.INTERSECTED.position.y + yOffset, this.INTERSECTED.position.z);
    this.selectionMesh.renderOrder = 3 + this.INTERSECTED.userData.floor * 10;
    this.scene.add(this.selectionMesh);
    this.showStatusText(dimentions.height);
  }

  showStatusText(yOffset: number) {
    const machine = this.INTERSECTED.userData as LayoutMachine;
    if (machine.static || machine.status === 'UNKNOWN') {
      return;
    }
    const strkeColor = 'rgba(0,0,0,0.8)';
    const sprite = this.makeTextSprite(machine.status, { fontsize: this.props.options.fontSize, color: machine.color, strokeColor: strkeColor });
    sprite.position.set(
      this.INTERSECTED.position.x,
      this.INTERSECTED.position.y + yOffset + this.props.options.fontSize,
      this.INTERSECTED.position.z
    );
    this.selectionStatusText = sprite;
    this.scene.add(sprite);
  }

  unselectMachine() {
    this.setState({ showPanelLeft: false, showPanelBottom: false });
    if (this.selectionMesh) {
      this.scene.remove(this.selectionMesh);
      this.selectionMesh = null;
    }
    if (this.selectionStatusText) {
      this.scene.remove(this.selectionStatusText);
      this.selectionStatusText = null;
    }
  }

  onDocumentMouseMove(e: any) {
    this.mouseHasMoved = true;
    if (this.mount) {
      const width = this.mount.clientWidth;
      const height = this.mount.clientHeight;
      this.mouse.x = (e.offsetX / width) * 2 - 1;
      this.mouse.y = -(e.offsetY / height) * 2 + 1;
    }
  }

  onTouchStart(e: any) {
    if (e.touches.length === 0) {
      return;
    }
    this.touchTimer = global.setTimeout(() => {
      this.onMouseDown(null);
    }, this.touchDuration);
    const touch = e.touches[0] || e.changedTouches[0];
    const realTarget: Element = document.elementFromPoint(touch.clientX, touch.clientY) as Element;
    const bound: ClientRect = realTarget.getBoundingClientRect();
    e.offsetX = touch.clientX - bound.left;
    e.offsetY = touch.clientY - bound.top;

    this.mouseHasMoved = true;
    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;

    this.mouse.x = (e.offsetX / width) * 2 - 1;
    this.mouse.y = -(e.offsetY / height) * 2 + 1;
  }

  onTouchEnd(e: any) {
    if (this.touchTimer) {
      clearTimeout(this.touchTimer);
    }
  }

  componentWillReceiveProps(props: Readonly<SceneProps>) {
    this.resize();
    this.updateMachine(props);
  }

  updateMachine(props: Readonly<SceneProps>) {
    const sceneMachine = this.machineMesh;
    if(sceneMachine && sceneMachine.userData.isDefault && !props.machine.isDefault){
      this.addMachine(props.machine);
    }
    if (sceneMachine) {
      //update status
      sceneMachine.userData = props.machine;
      this.updateMachineStatus(sceneMachine);
    }
  }

  updateMachineStatus(sceneMachine: THREE.Mesh) {
    sceneMachine.visible = sceneMachine.userData.visible;
    this.machineNumberSprite.visible = sceneMachine.userData.visible;
    if (sceneMachine.userData.static) {
      return;
    }
    if (this.props.options.showBase) {
      //base
      if (this.machineBase) {
        this.machineBase.visible = sceneMachine.userData.status !== 'UNKNOWN';
        this.machineBase.material.color.set(sceneMachine.userData.color);
        if (this.props.options.emissiveStatusLight) {
          this.machineBase.material.emissive.set(sceneMachine.userData.color);
        } else {
          this.machineBase.material.emissive.set(0x000000);
        }
      }
    } else {
      if (sceneMachine.userData.status !== 'UNKNOWN') {
        (sceneMachine.material as any).color.set(sceneMachine.userData.color);
        const segments = sceneMachine.children[1] as THREE.LineSegments;
        if (segments) {
          (segments.material as any).color.set(sceneMachine.userData.color);
        }
      } else {
        (sceneMachine.material as any).color.set(this.props.options.machineColor);
        const segments = sceneMachine.children[1] as THREE.LineSegments;
        if (segments) {
          (segments.material as any).color.set(this.props.options.machineColor);
        }
      }
    }

    //GLB machine status and animation
    if (sceneMachine.userData.path) {
      if (this.glbMachineStatusBase) {
        this.glbMachineStatusBase.visible = sceneMachine.userData.status !== 'UNKNOWN';
        this.glbMachineStatusBase.material.color.set(sceneMachine.userData.color);
        if (this.props.options.emissiveStatusLight) {
          this.glbMachineStatusBase.material.emissive.set(sceneMachine.userData.color);
        } else {
          this.glbMachineStatusBase.material.emissive.set(0x000000);
        }
      }
      //Add Animation Logic
      const animationData = this.machineAnimation;
      if (animationData && animationData.animations && animationData.configuration) {
        animationData.configuration.forEach((conf: any) => {
          const animation = animationData.animations[conf.id];
          const clipAnimation = animationData.mixer.clipAction(animation);
          if (conf.status && sceneMachine.userData.status && conf.status.toUpperCase() == sceneMachine.userData.status.toUpperCase()) {
            if (!clipAnimation.isRunning()) {
              clipAnimation.play();
            }
          }
          else {
            if (clipAnimation.isRunning()) {
              clipAnimation.stop();
            }
          }
        });
      }
    }

    //lamp
    if (this.machineLamp) {
      this.machineLamp.visible = sceneMachine.userData.status !== 'UNKNOWN' && sceneMachine.userData.visible;
      this.machineLampBase.visible = sceneMachine.userData.status !== 'UNKNOWN' && sceneMachine.userData.visible;
      this.machineLamp.material.color.set(sceneMachine.userData.color);
      if (this.props.options.emissiveStatusLight) {
        this.machineLamp.material.emissive.set(sceneMachine.userData.color);
      } else {
        this.machineLamp.material.emissive.set(0x000000);
      }
    }

    const dimentions = this.getMeshDimentions(sceneMachine);
    //shutdown required
    if (this.wrenchObject) {
      if (sceneMachine.userData.mms && (sceneMachine.userData.mms as MMSData).shutdownRequired) {
        if (this.wrenchDic) {
          this.wrenchDic.visible = sceneMachine.userData.visible;
        } else {
          const wrench = this.wrenchObject.clone() as Scene;
          wrench.position.set(
            sceneMachine.position.x,
            sceneMachine.position.y + dimentions.height + this.props.options.lampSize * 4,
            sceneMachine.position.z
          );
          this.wrenchDic = wrench;
          this.wrenchDic.visible = sceneMachine.userData.visible;
          this.scene.add(wrench);
        }
      } else if (this.wrenchDic) {
        this.scene.remove(this.wrenchDic);
        this.wrenchDic = null;
      }
    }

    //alarm
    if (this.alarmObject) {
      if (sceneMachine.userData.alarm && !(sceneMachine.userData.mms && (sceneMachine.userData.mms as MMSData).shutdownRequired)) {
        if (this.alarmsDic) {
          this.alarmsDic.visible = sceneMachine.userData.visible;
        } else {
          const alarm = this.alarmObject.clone() as Scene;
          alarm.position.set(
            sceneMachine.position.x,
            sceneMachine.position.y + dimentions.height + this.props.options.lampSize * 4,
            sceneMachine.position.z
          );
          this.alarmsDic = alarm;
          this.alarmsDic.visible = sceneMachine.userData.visible;
          this.scene.add(alarm);
        }
      } else {
        if (this.alarmsDic) {
          this.scene.remove(this.alarmsDic);
          this.alarmsDic = null;
        }
      }
    }
  }

  resize() {
    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;

    if (width !== this.state.width || height !== this.state.height) {
      this.setState({ width: width, height: height });
      this.renderer.setSize(width, height);
      this.camera.aspect = width / height;
      //reset position
      this.camera.position.set(this.props.options.cameraPosition.x, this.props.options.cameraPosition.y, this.props.options.cameraPosition.z);
      this.camera.updateProjectionMatrix();
    }
  }

  fullscreenResize() {
    setTimeout(() => {
      const width = this.mount.clientWidth;
      const height = this.mount.clientHeight;

      console.log('W=' + width + ' H=' + height);

      if (width !== this.state.width || height !== this.state.height) {
        this.setState({ width: width, height: height });
        this.renderer.setSize(width, height);
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
      }
    }, 150);
  }

  componentDidMount() {
    this.initializeScene();
    //EVENTS
    document.addEventListener('mousemove', e => this.onDocumentMouseMove(e), false);
    document.addEventListener('mousedown', e => this.onMouseDown(e), false);
    //touch
    document.addEventListener('touchstart', e => this.onTouchStart(e), false);
    document.addEventListener('touchend', e => this.onTouchEnd(e), false);
    //fullscreen
    if (screenfull.isEnabled) {
      this.fullscreenResize = this.fullscreenResize.bind(this);
      screenfull.onchange(this.fullscreenResize);
    }
  }

  initializeScene() {
    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;
    this.setState({ width: width, height: height });
    //ADD SCENE
    this.scene = new THREE.Scene();
    //ADD CAMERA
    this.camera = new THREE.PerspectiveCamera(30, width / height, 1, 20000);
    this.camera.position.set(this.props.options.cameraPosition.x, this.props.options.cameraPosition.y, this.props.options.cameraPosition.z);
    //ADD RENDERER
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.shadowMap.enabled = true;
    this.renderer.setClearColor('#000000');
    this.renderer.setSize(width, height);
    this.mount.appendChild(this.renderer.domElement);
    //CONTROLS
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.maxPolarAngle = Math.PI * 0.5;
    this.controls.zoomSpeed = 1;
    this.controls.minDistance = 100;
    this.controls.maxDistance = (this.props.options.mapWidth + this.props.options.mapHeight) * 2;
    this.controls.target.set(0, 50, 0);
    //ADD CLOCK
    this.clock = new THREE.Clock();

    //damping
    //this.controls.screenSpacePanning = false;
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.1;
    this.controls.update();
    //OTHER
    this.addLights();
    this.addBackground();
    this.loadAlarmObject();
    this.loadWrenchObject();
    this.getProxyUrl().then(r => {
      this.addMachine(this.props.machine);
    })
    this.addGrid();
    this.start();
  }

  getProxyUrl() {
    const publicApiName = 'public-api';
    const srv = getDataSourceSrv();
    return srv.get(publicApiName).then(response => {
      const us = response as any;
      this.proxyUrl = `/api/datasources/${us.id}/resources`;
    });
  }

  addGrid() {
    if (!this.props.options.grid) {
      return;
    }
    const size = ((this.props.options.mapHeight + this.props.options.mapHeight) / 2) * 10;
    const divisions = 100;
    const gridHelper = new THREE.GridHelper(
      size,
      divisions,
      new THREE.Color(this.props.options.gridColor),
      new THREE.Color(this.props.options.gridColor)
    );
    gridHelper.position.set(0, -2, 0);
    this.scene.add(gridHelper);
  }

  addMachine(machine: LayoutMachine) {
    if (machine.path) {
      this.addBoxMachine(machine);
      this.addGlbMachine(machine);
    }
    else {
      this.addBoxMachine(machine);
    }
  }

  addGlbMachine(machine: LayoutMachine) {
    const URL = window.location.origin + this.proxyUrl + '/publicapi/api/FactoryLayout/GetModelUrl/' + machine.path;
    axios.get(URL).then(
      r => {
        const loader = new GLTFLoader();
        loader.load(r.data,
          gltf => {
            this.handleGlbLoaded(machine, gltf)
          },
          undefined,
          error => console.log(error)
        );
      },
      e => {
        console.log(e);
      }
    );
  }

  handleGlbLoaded(machine: LayoutMachine, modelObject: any) {
    const mesh = this.createMachineMeshWrapper(modelObject.scene);

    mesh.castShadow = false;
    mesh.userData = machine;
    this.isGlb = true;

    mesh.scale.set(machine.scale, machine.scale, machine.scale);
    this.addGlbMachineStatusBase(mesh, machine);
    const dimentions = this.getMeshDimentions(mesh);
		mesh.position.set(0, dimentions.height / 2, 0);

    this.addMachineAnimation(machine, modelObject);
    if (this.machineMesh) {
      this.scene.remove(this.machineMesh);
    }
    this.addMachineNumber(mesh, true);
    if (!machine.static) {
      this.addMachineLamp(mesh, machine, true);
      this.updateMachineStatus(mesh);
    }
    this.machineMesh = mesh;
    this.scene.add(mesh);
  }

  addMachineAnimation(machine: LayoutMachine, modelObject: any) {
    const machineHasAnimations = modelObject.animations && modelObject.animations.length > 0;
    if (machineHasAnimations) {
      const mixer = new THREE.AnimationMixer(modelObject.scene);
      this.machineAnimation = {
        animations: modelObject.animations,
        configuration: machine.animations,
        mixer: mixer
      };
    }
  }

  createMachineMeshWrapper(mesh: THREE.Mesh) {
    const dimentions = this.getMeshDimentions(mesh);
    mesh.position.set(
      - dimentions.center.x,
      - dimentions.center.y,
      - dimentions.center.z
    );

    const geometry = new THREE.BoxGeometry(dimentions.width, dimentions.height, dimentions.depth);
    const material = new THREE.MeshBasicMaterial({
      color: 0x00ff00,
      opacity: 0,
      transparent: true
    });
    const cube = new THREE.Mesh(geometry, material);
    cube.add(mesh);

    return cube;
  }

  getMeshDimentions(mesh: THREE.Mesh) {
    const meshClone = mesh.clone();
    const gltfbox = new THREE.Box3().setFromObject(meshClone);
    const objectwidth = gltfbox.getSize(new Vector3()).x;
    const objectheight = gltfbox.getSize(new Vector3()).y;
    const objectdepth = gltfbox.getSize(new Vector3()).z;

    const center = gltfbox.getCenter(new Vector3());

    return {
      width: objectwidth,
      height: objectheight,
      depth: objectdepth,
      center: center
    }
  }

  addBoxMachine(machine: LayoutMachine) {
    if(this.machineMesh){
      this.scene.remove(this.machineMesh);
    }
    const mesh = this.makeMachineMesh(machine);
    // wireframe
    const geo = new THREE.EdgesGeometry(mesh.geometry); // or WireframeGeometry
    const mat = new THREE.LineBasicMaterial({ color: this.props.options.machineColor, linewidth: 4 });
    const wireframe = new THREE.LineSegments(geo, mat);
    mesh.add(wireframe);

    if (machine.face === 'top') {
      this.machineImage.position.setY(machine.depth / 2);
      this.machineImage.position.setZ(machine.depth / 2 + 1);
    } else {
      this.machineImage.position.setZ(machine.depth / 2 + 1);
    }

    this.machineMesh = mesh;
    this.addMachineNumber(mesh, false);

    if (machine.hasImage) {
      this.loadMachineImage(mesh);
    }
    else {
      this.loadDefaultMachineImage(mesh);
    }

    if (!machine.static) {
      this.addMachineBase(mesh, machine);
      this.addMachineLamp(mesh, machine, false);
      this.updateMachineStatus(mesh);
    }
    mesh.rotation.y = THREE.Math.degToRad(this.rotate);
    this.scene.add(mesh);
  }

  addMachineBase(mesh: THREE.Mesh, machine: LayoutMachine) {
    if (!this.props.options.showBase) {
      return;
    }
    const geometry = new THREE.BoxGeometry(machine.width + this.props.options.baseSize, 4, machine.depth + this.props.options.baseSize);
    const baseMesh = new THREE.Mesh(geometry, new THREE.MeshLambertMaterial({ color: '#ffffff' }));
    baseMesh.position.setY(baseMesh.position.y - 2);
    baseMesh.castShadow = false;
    if (this.machineBase) {
      this.scene.remove(this.machineBase);
    }
    this.machineBase = baseMesh;
    mesh.add(baseMesh);
  }

  addGlbMachineStatusBase(mesh: THREE.Mesh, machine: LayoutMachine) {
    const machineSize = this.getMeshDimentions(mesh);
    const geometry = new THREE.BoxGeometry(machineSize.width + 4, 4, machineSize.depth + 4);
    const baseMesh = new THREE.Mesh(geometry, new THREE.MeshLambertMaterial({ color: '#ffffff' }));
    baseMesh.position.set(0, 0, 0);
    baseMesh.castShadow = false;
    if (this.glbMachineStatusBase) {
      this.scene.remove(this.glbMachineStatusBase);
    }
    this.glbMachineStatusBase = baseMesh;
    this.scene.add(baseMesh);
  }

  addMachineLamp(mesh: THREE.Mesh, machine: LayoutMachine, isGlb: boolean) {
    if (!this.props.options.showLamp) {
      return;
    }
    let heightDivider = 1;
    if(isGlb) {
     heightDivider = 2; 
    }
    const geometry = new THREE.CylinderGeometry(this.props.options.lampSize, this.props.options.lampSize, this.props.options.lampSize * 2);
    geometry.translate(0, (machine.height / heightDivider) + this.props.options.lampSize, 0);
    const lampMesh = new THREE.Mesh(geometry, new THREE.MeshLambertMaterial({ color: '#ffffff' }));
    lampMesh.position.copy(mesh.position);
    lampMesh.castShadow = false;
    if (this.machineLamp) {
      this.scene.remove(this.machineLamp)
    }
    this.machineLamp = lampMesh;
    this.scene.add(lampMesh);
    //lamp base
    const baseGeometry = new THREE.CylinderGeometry(
      this.props.options.lampSize * 1.2,
      this.props.options.lampSize * 1.2,
      this.props.options.lampSize * 0.2
    );
    baseGeometry.translate(0, (machine.height / heightDivider) + this.props.options.lampSize * 0.1, 0);
    const baseMesh = new THREE.Mesh(baseGeometry, new THREE.MeshLambertMaterial({ color: '#5A5A5A' }));
    baseMesh.position.copy(mesh.position);
    baseMesh.castShadow = false;
    if (this.machineLampBase) {
      this.scene.remove(this.machineLampBase)
    }
    this.machineLampBase = baseMesh;
    this.scene.add(baseMesh);
  }

  makeMachineMesh(machine: LayoutMachine): THREE.Mesh {
    const width = machine.width,
      height = machine.height,
      depth = machine.depth;
    const geometry = new THREE.BoxGeometry(width, height, depth);
    this.rotate = 0;
    switch (machine.face) {
      case 'west':
        this.machineImage = new THREE.Mesh(
          new THREE.BoxGeometry(1, height, depth).translate(-width / 2 - 1, height / 2, -depth / 2),
          new THREE.MeshLambertMaterial({ color: '#ffffff' })
        );
        this.rotate = 90;
        break;
      case 'north':
        this.machineImage = new THREE.Mesh(
          new THREE.BoxGeometry(width, height, 1).translate(0, height / 2, -depth - 1),
          new THREE.MeshLambertMaterial({ color: '#ffffff' })
        );
        this.rotate = 180;
        break;
      case 'east':
        this.machineImage = new THREE.Mesh(
          new THREE.BoxGeometry(1, height, depth).translate(width / 2, height / 2, -depth / 2),
          new THREE.MeshLambertMaterial({ color: '#ffffff' })
        );
        this.rotate = -90;
        break;
      case 'top':
        this.machineImage = new THREE.Mesh(
          new THREE.BoxGeometry(width, depth, 1).translate(0, 0, -depth / 2),
          new THREE.MeshLambertMaterial({ color: '#ffffff' })
        );
        break;
      default:
        this.machineImage = new THREE.Mesh(
          new THREE.BoxGeometry(width, height, 1).translate(0, height / 2, 0),
          new THREE.MeshLambertMaterial({ color: '#ffffff' })
        );
        break;
    }

    geometry.translate(0, height / 2, 0);
    const material = new THREE.MeshPhongMaterial({
      color: this.props.options.machineColor,
      transparent: true,
      opacity: this.props.options.machineOpacity,
      polygonOffset: true,
      polygonOffsetFactor: 1, // positive value pushes polygon further away
      polygonOffsetUnits: 1,
    });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.castShadow = false;
    mesh.userData = machine;
    this.isGlb = false;
    mesh.add(this.machineImage);
    return mesh;
  }

  addMachineNumber(mesh: THREE.Mesh, isGlb: boolean) {
    const machine = mesh.userData as LayoutMachine;
    const sprite = this.makeTextSprite(machine.name, { fontsize: this.props.options.fontSize });
    const dimentions = this.getMeshDimentions(mesh);
    let adjustedHeight = dimentions.height > 1 ? dimentions.height : dimentions.depth;
    if(isGlb){
      adjustedHeight = adjustedHeight / 2;
    }
    sprite.position.set(mesh.position.x, mesh.position.y + adjustedHeight * 1.1, mesh.position.z);
    if (this.machineNumberSprite) {
      this.scene.remove(this.machineNumberSprite);
    }
    this.machineNumberSprite = sprite;
    this.scene.add(sprite);
  }

  makeTextSprite(message: string, opts: any): THREE.Sprite {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;
    const fontsize = opts.hasOwnProperty('fontsize') ? opts['fontsize'] : 50;
    ctx.font = 'Bold ' + fontsize + 'px Arial';
    ctx.strokeStyle = opts.hasOwnProperty('strokeColor') ? opts['strokeColor'] : 'rgba(0,0,0,0.8)';
    ctx.lineWidth = 4;
    ctx.textAlign = 'center';
    ctx.strokeText(message, 150, fontsize, 300);
    ctx.fillStyle = opts.hasOwnProperty('color') ? opts['color'] : 'rgba(255,255,255,0.9)';
    ctx.fillText(message, 150, fontsize, 300);
    const texture1 = new THREE.Texture(canvas);
    texture1.needsUpdate = true;
    const spriteMaterial = new THREE.SpriteMaterial({
      map: texture1,
      transparent: true,
      side: THREE.DoubleSide,
      depthWrite: false,
    });
    const sprite = new THREE.Sprite(spriteMaterial);
    sprite.scale.set(3 * fontsize, 2 * fontsize, 1);
    return sprite;
  }

  componentWillUnmount() {
    this.stop();
    this.mount.removeChild(this.renderer.domElement);
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };

  stop = () => {
    cancelAnimationFrame(this.frameId);
  };

  animate = () => {
    if (this.machineAnimation) {
      const delta = this.clock.getDelta();
      const animation = this.machineAnimation;
      animation.mixer.update(delta);
    }

    this.controls.update();
    this.rotateObjects();
    this.renderScene();
    this.frameId = window.requestAnimationFrame(this.animate);
    this.updateOnMouseMove();
    TWEEN.update();
  };

  renderScene = () => {
    this.renderer.render(this.scene, this.camera);
  };

  addBackground() {
    this.scene.background = new THREE.Color(this.props.options.backgroundColor);
    this.scene.fog = new THREE.Fog(0x000000, 500, ((this.props.options.mapHeight + this.props.options.mapHeight) / 2) * 10);
  }

  addLights() {
    const d = this.props.options.mapWidth + this.props.options.mapHeight;
    this.scene.add(new THREE.AmbientLight(0x666666, 0.6));
    const light = new THREE.DirectionalLight(0xdfebff, 0.8);
    light.position.set(1500, 1700, 700);
    light.castShadow = false;
    light.shadow.mapSize.width = 4024;
    light.shadow.mapSize.height = 4024;

    light.shadow.camera.left = -d;
    light.shadow.camera.right = d;
    light.shadow.camera.top = d;
    light.shadow.camera.bottom = -d;
    light.shadow.camera.far = d;
    this.scene.add(light);
    const hemiLight = new THREE.HemisphereLight(0xddeeff, 0x0f0e0d, 0.2);
    this.scene.add(hemiLight);
  }
}
export default ThreeScene;
