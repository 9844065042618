import { PanelPlugin } from '@grafana/data';
import { defaults, FactoryLayoutOptions } from './types';
import { MachineVisualizationPanel } from './MachineVisualizationPanel';
import { ColorSetter } from 'controls/ColorSetter';

export const plugin = new PanelPlugin<FactoryLayoutOptions>(MachineVisualizationPanel).setPanelOptions(builder => {
    return builder
        .addNumberInput({
            name: 'Map width',
            path: 'mapWidth',
            category: ['Map'],
            defaultValue: defaults.mapWidth
        })
        .addNumberInput({
            name: 'Map height',
            path: 'mapHeight',
            category: ['Map'],
            defaultValue: defaults.mapHeight
        })
        .addColorPicker({
            name: 'Background color',
            path: 'backgroundColor',
            category: ['Map'],
            defaultValue: defaults.backgroundColor
        })
        .addBooleanSwitch({
            name: 'Grid',
            path: 'grid',
            category: ['Map'],
            defaultValue: defaults.grid
        })
        .addColorPicker({
            name: 'Grid color',
            path: 'gridColor',
            category: ['Map'],
            defaultValue: defaults.gridColor + ''
        })
        .addNumberInput({
            name: 'Font size',
            path: 'fontSize',
            category: ['Machines'],
            defaultValue: defaults.fontSize
        })
        .addNumberInput({
            name: 'Alarm icon size',
            path: 'alarmIconSize',
            category: ['Machines'],
            defaultValue: defaults.alarmIconSize
        })
        .addNumberInput({
            name: 'Wrench icon size',
            path: 'wrenchIconSize',
            category: ['Machines'],
            defaultValue: defaults.wrenchIconSize
        })
        .addBooleanSwitch({
            name: 'Show status base',
            path: 'showBase',
            category: ['Machines'],
            defaultValue: defaults.showBase
        })
        .addNumberInput({
            name: 'Base size',
            path: 'baseSize',
            showIf: o => o.showBase,
            category: ['Machines'],
            defaultValue: defaults.baseSize
        })
        .addBooleanSwitch({
            name: 'Show status lamp',
            path: 'showLamp',
            category: ['Machines'],
            defaultValue: defaults.showLamp
        })
        .addNumberInput({
            name: 'Lamp size',
            path: 'lampSize',
            showIf: o => o.showLamp,
            category: ['Machines'],
            defaultValue: defaults.lampSize
        })
        .addBooleanSwitch({
            name: 'Emissive status light',
            path: 'emissiveStatusLight',
            category: ['Machines'],
            defaultValue: defaults.emissiveStatusLight
        })
        .addNumberInput({
            name: 'Machine opacity',
            path: 'machineOpacity',
            category: ['Machines'],
            defaultValue: defaults.machineOpacity
        })
        .addColorPicker({
            name: 'Machine color',
            path: 'machineColor',
            category: ['Machines'],
            defaultValue: defaults.machineColor
        })
        .addTextInput({
            name: 'Not Online Status Filter (CSV)',
            path: 'notOnlineStatusFilter',
            category: ['Machines'],
            defaultValue: defaults.notOnlineStatusFilter
        })
        .addNumberInput({
            name: 'Camera position x',
            path: 'cameraPosition.x',
            category: ['Camera'],
            defaultValue: defaults.cameraPosition.x
        })
        .addNumberInput({
            name: 'Camera position y',
            path: 'cameraPosition.y',
            category: ['Camera'],
            defaultValue: defaults.cameraPosition.y
        })
        .addNumberInput({
            name: 'Camera position z',
            path: 'cameraPosition.z',
            category: ['Camera'],
            defaultValue: defaults.cameraPosition.z
        })
        .addCustomEditor({
            name: 'Statuses',
            path: 'statuses',
            editor: ColorSetter,
            id: '1',
            category: ['Statuses']
        })
})
