import React, { PureComponent } from 'react';
import { PanelProps } from '@grafana/data';
import { FactoryLayoutOptions, LayoutMachine, MachineStatus, MMSData, MachineAnimationConfig } from 'types';
import ThreeScene from 'Scene';
import { loadPluginCss } from '@grafana/runtime';

interface Props extends PanelProps<FactoryLayoutOptions> { }

export class MachineVisualizationPanel extends PureComponent<Props> {
  selectedMachine: string = "";
  machine: LayoutMachine = this.getDefaultMachine();
  cssLoaded = false;
  filterListDepartment: string[] = [];
  filterListTeam: string[] = [];

  render() {
    if (!this.cssLoaded) {
      this.cssLoaded = true;
      loadPluginCss({
        dark: 'plugins/machine-visualization-panel/css/machine_visualization_base.css',
        light: 'plugins/machine-visualization-panel/css/machine_visualization_base.css',
      }).then(() => this.setState({ cssLoaded: true }));
      return null;
    }

    if (this.props.data.state === 'Done') {
      this.getMachineFromQuery();
      if (!this.machine) {
        return null;
      }

      this.applyMachineStatus();
    }
    else{
      this.machine = this.machine;
    }

    const { options } = this.props;
    const fullscreenTargetId = 'machine_visualization_' + this.props.id;
    return (
      <div id={fullscreenTargetId} style={{ width: '100%', height: '100%' }}>
        <ThreeScene machine={this.machine} options={options} />
      </div>
    );
  }

  applyMachineStatus() {
    const statuses: MachineStatus[] = this.getLastStatuses();
    if (statuses.length === 0) {
      return;
    }
    if(this.machine){
      const m = this.machine;
      const status = statuses.find(s => s.sensorId === m.sensorId);
      if (!status || !status.status) {
        m.color = '#000000';
        m.status = 'UNKNOWN';
      } else {
        m.color = this.FindStatusColor(status.status);
        m.status = status.status;
        m.alarm = status.alarm > 0;
      }
    }
  }

  FindStatusColor(status: string): string {
    const color = '#000000';
    if(!this.props.options.statuses){
      return color;
    }
    const statusColor = this.props.options.statuses.find(s => s.status.trim().toUpperCase() === status.trim().toUpperCase());
    if (statusColor !== undefined) {
      return statusColor.color;
    }
    return color;
  }

  getLastStatuses(): MachineStatus[] {
    const statuses: MachineStatus[] = [];
    const table = this.props.data.series.find(s => s.fields.length === 4);
    if (table !== undefined) {
      const colSensor = table.fields[1].values.toArray();
      const colStatus = table.fields[2].values.toArray();
      const colAlarm = table.fields[3].values.toArray();
      table.fields[0].values.toArray().forEach((r: string, i) => {
        const s: MachineStatus = {
          sensorId: colSensor[i],
          status: colStatus[i],
          alarm: this.checkOnlineStatus(colStatus[i], colAlarm[i]),
          time: r,
        };
        statuses.push(s);
      });
    }
    return statuses;
  }

  getMachineFromQuery() {
    const urlParams = new URLSearchParams(window.location.search);

    if (this.selectedMachine !== urlParams.get('var-sensor')) {
      this.selectedMachine = urlParams.get('var-sensor') ?? '';
    }

    let machines: LayoutMachine[] = [];
    const table = this.props.data.series.find(s => s.refId === 'A');
    if (table !== undefined) {
      const colWidth = table.fields.filter(a => a.name == "Width")[0].values.toArray();
      const colHeight = table.fields.filter(a => a.name == "Height")[0].values.toArray();
      const colDepth = table.fields.filter(a => a.name == "Depth")[0].values.toArray();
      const colFace = table.fields.filter(a => a.name == "Face")[0].values.toArray();
      const colName = table.fields.filter(a => a.name == "Name")[0].values.toArray();
      const colRotate = table.fields.filter(a => a.name == "Rotate")[0].values.toArray();
      const colSensor = table.fields.filter(a => a.name == "SensorID")[0].values.toArray();
      const mmsShutdownReq = table.fields.filter(a => a.name == "MMSShutdownRequired")[0].values.toArray();
      const mmsShortDescription = table.fields.filter(a => a.name == "MMSShortDescription")[0].values.toArray();
      const mmsDescription = table.fields.filter(a => a.name == "MMSDescription")[0].values.toArray();
      const mmsStart = table.fields.filter(a => a.name == "MMSStartDate")[0].values.toArray();
      const mmsEnd = table.fields.filter(a => a.name == "MMSEndDate")[0].values.toArray();
      const mmsNumber = table.fields.filter(a => a.name == "MMSNumber")[0].values.toArray();
      const path = table.fields.filter(a => a.name == "Path")[0].values.toArray();
      const scale = table.fields.filter(a => a.name == "Scale")[0].values.toArray();
      const animations = table.fields.filter(a => a.name == "Animations")[0].values.toArray();
      const colHasImage = table.fields.filter(a => a.name == "HasImage")[0].values.toArray();
      machines = table.fields.filter(a => a.name == "OrgItemID")[0].values.toArray().map((r: number, i) => {
        const m: LayoutMachine = {
          orgItemId: r,
          sensorId: (!r) ? colName[i] : colSensor[i],
          name: (colName[i]) ? colName[i] : colSensor[i],
          width: colWidth[i],
          height: colHeight[i],
          depth: colDepth[i],
          color: 'rgba(0,0,0,0.4)',
          status: 'UNKNOWN',
          alarm: false,
          face: colFace[i],
          static: (!r),
          rotate: colRotate[i],
          visible: true,
          mms: null,
          path: path[i],
          scale: scale[i],
          animations: this.getAnimations(animations[i]),
          hasImage: colHasImage[i],
          isDefault: false
        };
        if (mmsShutdownReq[i] === 1) {
          const mms: MMSData = {
            shutdownRequired: true,
            description: mmsDescription[i],
            shortDescription: mmsShortDescription[i],
            startDate: mmsStart[i],
            endDate: mmsEnd[i],
            number: mmsNumber[i],
          };
          m.mms = mms;
        }
        return m;
      });
    }
    const foundMachine = machines.find(m => m.sensorId == this.selectedMachine);
    if(foundMachine){
      this.machine = foundMachine;
    }
  }

  getDefaultMachine() : LayoutMachine
  {
    const result : LayoutMachine = {
      sensorId: this.selectedMachine,
      name: this.selectedMachine,
      width: 50,
      height: 50,
      depth: 50,
      color: 'rgba(0,0,0,0.4)',
      status: 'UNKNOWN',
      alarm: false,
      face: 'default',
      static: false,
      rotate: 0,
      visible: true,
      mms: null,
      hasImage: false,
      orgItemId: 0,
      path: '',
      scale: 0,
      animations: [],
      isDefault: true
    };

    return result;
  }

  getAnimations(animationsString: string): MachineAnimationConfig[] {
    const result: MachineAnimationConfig[] = [];
    if (animationsString !== null && animationsString !== "") {
      const animationConfiguration = JSON.parse(animationsString);
      Object.keys(animationConfiguration).forEach(e => {
        result.push({ id: parseInt(e), status: animationConfiguration[e] })
      });
    }

    return result;
  }

  checkOnlineStatus(status: any, alarms: any): any {
    const filterText = this.checkForVariable(this.props.options.notOnlineStatusFilter);
    if (!filterText) {
      return alarms;
    }
    const notOnlineStatuses = filterText.split(',');
    if (notOnlineStatuses.includes(status)) {
      return null;
    } else {
      return alarms;
    }
  }

  checkForVariable(text: string): string {
    const isVar = text.startsWith('$');
    if (!isVar) {
      return text;
    }
    const varText = this.props.replaceVariables(text);
    return varText;
  }
}
