import { Vector3 } from 'three';

export interface FactoryLayoutOptions {
  machineOpacity: number;
  machineColor: string;

  mapWidth: number;
  mapHeight: number;
  backgroundColor: string;

  grid: boolean;
  gridColor: string;

  statuses: StatusDefinition[];
  fontSize: number;
  alarmIconSize: number;
  wrenchIconSize: number;

  showBase: boolean;
  baseSize: number;

  showLamp: boolean;
  lampSize: number;

  emissiveStatusLight: boolean;

  cameraPosition: Vector3;

  notOnlineStatusFilter: string;
}

export const defaults: FactoryLayoutOptions = {
  mapWidth: 500,
  mapHeight: 250,
  backgroundColor: '#000000',
  statuses: [],
  fontSize: 40,
  alarmIconSize: 5,
  wrenchIconSize: 6,
  showBase: true,
  baseSize: 10,
  showLamp: true,
  lampSize: 10,
  emissiveStatusLight: true,
  machineColor: '#000000',
  machineOpacity: 0.8,
  grid: true,
  gridColor: '#ffffff',
  cameraPosition: new Vector3(0, 300, 500),
  notOnlineStatusFilter: '',
};

export type SceneProps = {
  machine: LayoutMachine;
  options: FactoryLayoutOptions;
};

export type LayoutMachine = {
  orgItemId: number;
  name: string;
  sensorId: string;
  width: number;
  height: number;
  depth: number;
  color: string;
  status: string;
  alarm: boolean;
  face: string;
  rotate: number;
  static: boolean; // Static box, not machine with status. For object not present in OrganizationItems
  visible: boolean; //false when filtering is applied
  mms: MMSData | null;
  path: string;
  scale: number;
  animations: MachineAnimationConfig[];
  hasImage: boolean;
  isDefault: boolean;
};

export type MachineAnimationConfig = {
  id: number;
  status: string;
}

export type MMSData = {
  startDate: string;
  endDate: string;
  shortDescription: string;
  description: string;
  shutdownRequired: boolean;
  number: number;
};

export type MachineStatus = {
  status: string;
  sensorId: string;
  time: string;
  alarm: number;
};

export type StatusDefinition = {
  status: string;
  color: string;
};